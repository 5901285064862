const routesConstants = {
  TEAM_MANAGEMENT: "/team-management",
  TEAM_MANAGEMENT_ADD_USER: "/team-management/add-user",
  TEAM_MANAGEMENT_ADD_ROLE: "/team-management/add-role",
  STATIC_PAGE: "/static-page",
  ADD_STATIC_PAGE: "/static-page/add-page",
  EDIT_STATIC_PAGE: "/static-page/edit",
  SHOP_AND_WIN: "/shop-and-win",
  SHOP_AND_WIN_CREATE_CAMPAIGN: "/shop-and-win/create-campaign",
  SHOP_AND_WIN_EDIT_CAMPAIGN: "/shop-and-win/edit",
  SHOP_AND_WIN_VIEW_CAMPAIGN: "/shop-and-win/view",
  SHOP_AND_WIN_VIEW_USER: "/shop-and-win/view-user",
  SPIN_THE_WHEEL: "/spin-wheel",
  SPIN_THE_WHEEL_CREATE_CAMPAIGN: "/spin-wheel/create",
  SPIN_THE_WHEEL_EDIT_CAMPAIGN: "/spin-wheel/edit",
  SPIN_THE_WHEEL_CLONE_CAMPAIGN: "/spin-wheel/clone",
  SPIN_THE_WHEEL_VIEW_CAMPAIGN: "/spin-wheel/view",
  SPIN_THE_WHEEL_VIEW_USERS: "/spin-wheel/view-users",
};

export default routesConstants;
